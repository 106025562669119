import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import {
  ccbiffbamboomRegular,
  mainWhite,
  mainOrange,
  innerWidth,
  montserratRegular,
  darkBlue,
  screen,
  transHover,
} from "../components/common/variables";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import plainBg from "../images/about/home-background.jpg";

const Wrapper = styled.div`
  background-image: url(${plainBg});
  background-repeat: repeat-y;
  background-position: center top;
  overflow: hidden;
  padding: 85px 0;
  @media ${screen.small} {
    padding: 100px 0;
  }

  @media ${screen.large} {
    padding: 150px 0;
  }

  .intro {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }

    &__heading {
      color: ${darkBlue};
      font-family: ${ccbiffbamboomRegular};
      font-size: 3rem;
      max-width: 800px;
      @media ${screen.small} {
        font-size: 5rem;
      }
    }

    &__description {
      max-width: 1160px;
      margin-top: 20px;

      p {
        color: ${darkBlue};
        font-family: ${montserratRegular};
        font-weight: 400;
        font-size: 1.05rem;
        @media ${screen.small} {
          font-size: 1.25rem;
        }
      }

      a {
        background: ${mainOrange};
        color: ${mainWhite};
        transition: ${transHover};
        &:hover {
          background: none;
          color: ${mainOrange};
        }
      }
    }
  }

  .members {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }

    .member-list {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 0 -18px;
      @media ${screen.small} {
        margin: 0 -30px;
      }
      @media ${screen.medium} {
        margin: 0 -40px;
      }

      &__item {
        width: calc(50% - 36px);
        margin: 18px;
        @media ${screen.small} {
          margin: 30px;
          width: calc(33.33% - 60px);
        }
        @media ${screen.medium} {
          margin: 40px;
          width: calc(25% - 80px);
        }

        .member-img {
          width: 100%;
        }

        .member-name {
          color: ${darkBlue};
          font-family: ${ccbiffbamboomRegular};
          font-size: 1.2rem;
          margin-top: 16px;
          text-align: center;
          @media ${screen.small} {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .bottom-text {
    max-width: ${innerWidth};
    margin: 24px auto 0 auto;
    padding-left: 30px;
    padding-right: 30px;
    @media ${screen.small} {
      padding-left: 50px;
      padding-right: 50px;
    }

    p {
      color: ${darkBlue};
      font-family: ${montserratRegular};
      font-weight: 400;
      font-size: 1.05rem;
      max-width: 700px;
      @media ${screen.small} {
        font-size: 1.25rem;
      }
    }

    a {
      background: ${mainOrange};
      color: ${mainWhite};
      display: table;
      font-family: ${ccbiffbamboomRegular};
      font-size: 1.25rem;
      padding: 11px 19px;
      margin-top: 16px;
      transition: ${transHover};
      @media ${screen.small} {
        font-size: 1.5rem;
        margin-top: 24px;
        padding: 13px 20px;
      }

      &:hover {
        background: ${darkBlue};
      }
    }
  }
`;

const BoardMembers = ({ data }) => {
  const {
    member_list,
    title_tag,
    meta_description,
    heading,
    description,
  } = data.members.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="intro">
          <h1 className="intro__heading">{heading}</h1>
          <div
            className="intro__description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>

        <div className="members">
          <ul className="member-list">
            {member_list.map((member, i) => (
              <li className="member-list__item" key={i}>
                <div className="member-img">
                  {member.image.gatsbyImageData ? (
                    <GatsbyImage
                      image={member.image.gatsbyImageData}
                      alt={member.image.alt || "Person"}
                    />
                  ) : (
                    <StaticImage src="../images/empty-img.jpg" alt="Person" />
                  )}
                </div>
                <h3 className="member-name">{member.full_name}</h3>
              </li>
            ))}
          </ul>
        </div>

        <div className="bottom-text">
          <p>
            If you want to know more about the board members, you can read their
            bios on the Lysicrates Foundation website.
          </p>
          <a
            href="https://lysicratesfoundation.org.au/board"
            target="_blank"
            rel="noopener noreferrer"
          >
            FIND OUT MORE
          </a>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default BoardMembers;

export const dataQuery = graphql`
  {
    members: prismicBoardMembersPage {
      data {
        heading
        description {
          html
        }
        member_list {
          full_name
          image {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        title_tag
        meta_description
      }
    }
  }
`;
